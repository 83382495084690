import React from "react"
import Seo from "../components/seo"

const AccessibilityPage = () => {
  return (
    <section id="accessibility">
      <Seo title="Accessibility" />
      <div className="container">
        <div className="content">
          <div className="text-body">
            <p>
              Good4U is committed to providing a website that is accessible to
              the widest possible audience, regardless of technology or ability.
              We are actively working to increase the accessibility and
              usability of our website and in doing so adhere to many of the
              available standards and guidelines.
            </p>
            <p>
              This website endeavors to conform to level Double-A of the World
              Wide Web Consortium (W3C){" "}
              <a target="_blank" href="https://www.w3.org/TR/WCAG20/">
                Web Content Accessibility Guidelines 2.0
              </a>
              . These guidelines explain how to make web content more accessible
              for people with disabilities. Conformance with these guidelines
              will help make the web more user-friendly for all people.
            </p>
            <p>
              Although Good4U strives to adhere to the accepted guidelines and
              standards for accessibility and usability, it is not always
              possible to do so in all areas of the website.
            </p>
            <p>
              We are continually seeking out solutions that will bring all areas
              of the site up to the same level of overall web accessibility. In
              the meantime, should you experience any difficulty in accessing
              any of our websites, please do not hesitate to contact us.
            </p>
          </div>
          <div className="contact">
            <p>
              Contact Us Via Email:{" "}
              <a href="mailto:info@gogood4u.com">info@gogood4u.com</a>
            </p>
            <p>
              Telephone: <a href="tel:559-903-7893">559-903-7893</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AccessibilityPage
